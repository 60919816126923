import { useToast } from "@repo/ui";
import { ComponentProps, useCallback, useEffect, useState } from "react";
import {
  forceCompanySync,
  getCompany as getCompanyApi,
} from "../../../../apis/company";
import {
  deleteConnection,
  updateEntityConfiguration,
  updateOrganizationalEntity,
} from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import {
  Badge,
  Button,
  ConfirmModal,
  InlineAlert,
  Modal,
  SearchInput,
  SelectionBox,
  TextButton,
  ToggleInput,
} from "../../../shared/components";

type PropsT = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  setup: boolean;
};

type InlineAlertProps = ComponentProps<typeof InlineAlert>;

const SelectEntitiesModal = (props: PropsT) => {
  const { onSuccess, setOpen, open, setup } = props;
  const {
    organizationalEntities,
    getAllOrganizationalEntities,
    company,
    user,
    getCompany,
  } = useAuth();
  const { toast } = useToast();

  const connection = company?.Connections?.[0];

  const [messages, setMessages] = useState<InlineAlertProps[]>([]);

  const [isTopLevelLoading, setIsTopLevelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteConnectionConfirmOpen, setDeleteConnectionConfirmOpen] =
    useState(false);
  const [isDeletingConnection, setIsDeletingConnection] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<Set<number>>(
    new Set()
  );
  const [isUsingTopLevel, setIsUsingTopLevel] = useState(false);
  const [topLevelError, setTopLevelError] = useState(false);
  const canSetDefaultTopToTopLevel = organizationalEntities?.some(
    (entity) => entity.is_top_level
  );

  const selectableEntities = organizationalEntities.filter((entity) =>
    entity.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleSync = useCallback(async () => {
    if (!user?.token) return;
    setIsLoading(true);
    await updateEntityConfiguration({
      entity_ids: Array.from(selectedOptions),
      default_to_top_level: canSetDefaultTopToTopLevel
        ? isUsingTopLevel
        : false,
    });

    if (!setup) {
      await forceCompanySync();
    }

    await getAllOrganizationalEntities();

    await getCompany(user.token);

    toast({
      variant: "success",
      title: "Multiple entities added successfully!",
    });
    setIsLoading(false);
    onSuccess();
  }, [
    user?.token,
    selectedOptions,
    canSetDefaultTopToTopLevel,
    isUsingTopLevel,
    setup,
    getAllOrganizationalEntities,
    getCompany,
    toast,
    onSuccess,
  ]);

  const handleSelect = useCallback(async (entity: OrganizationalEntityT) => {
    setSelectedOptions((previousSelected) => {
      const newSelectedOptions = new Set(previousSelected);
      if (newSelectedOptions.has(entity.id)) {
        newSelectedOptions.delete(entity.id);
      } else {
        newSelectedOptions.add(entity.id);
      }

      return newSelectedOptions;
    });
  }, []);

  const handleToggleTopLevel = useCallback(
    async (entity: OrganizationalEntityT) => {
      if (isTopLevelLoading) return;
      setIsTopLevelLoading(true);
      await updateOrganizationalEntity(entity.id, {
        is_top_level: !entity.is_top_level,
      });
      const result = await getAllOrganizationalEntities();
      if (
        !result.organizationalEntities.some((entity) => entity.is_top_level)
      ) {
        setIsUsingTopLevel(false);
      }
      setTopLevelError(false);
      setMessages((prev) => {
        return [
          ...prev,
          {
            type: "success",
            variant: "snug",
            title: "",
            subtitle: entity.is_top_level
              ? "Top level entity unset successfully"
              : "Top level entity set successfully!",
          },
        ];
      });
      setIsTopLevelLoading(false);
    },
    [getAllOrganizationalEntities, isTopLevelLoading]
  );

  const handleCancel = useCallback(() => {
    setOpen(false);
    if (connection) {
      if (!setup) setDeleteConnectionConfirmOpen(true);
    }
  }, [setup, setOpen, connection]);

  const handleSelectAll = async () => {
    setSelectedOptions(new Set(organizationalEntities.map((x) => x.id)));
  };
  const handleDeselectAll = async () => {
    setSelectedOptions(new Set());
  };

  const handleToggle = useCallback(() => {
    if (canSetDefaultTopToTopLevel) {
      setTopLevelError(false);
      setIsUsingTopLevel(!isUsingTopLevel);
    } else if (!isUsingTopLevel) {
      setTopLevelError(true);
    }
  }, [canSetDefaultTopToTopLevel, isUsingTopLevel]);

  const handleDisconnect = useCallback(async () => {
    if (user?.token) {
      setIsDeletingConnection(true);
      /**
       * We do this twice because we don't want to notify the rest of the front end that the connection has been added yet. Basically there are 2 flows:
       * 1. We exit through here
       * 2. We exit unclean (close tab).
       * Effectively, we use local state to determine whether or not the user exited the setup cleanly or not.
       * If the user did not exit cleanly, we need to delete the connection.
       * We fetch the company because we do not want to alert the rest of the app that the user completed the connection (yet)
       * This will happen when the app reloads.
       * Ideally this would be better if we had some global state management for only modals
       * (that way we could just prevent opening up the "IncompleteSetup" modal based on whether this modal is open)
       * This will work... for now.
       */
      const localCompanyResponse = await getCompanyApi();
      const connection = localCompanyResponse.company?.Connections?.[0];
      if (connection) {
        // If we don't reach heree, that means we have no connection
        await deleteConnection(connection.id || 0);
      }
      await getCompany(user.token);
      setIsDeletingConnection(false);
      setDeleteConnectionConfirmOpen(false);
      setOpen(false);
    }
  }, [user?.token, getCompany, setOpen]);

  const isSelected = (option: OrganizationalEntityT) =>
    selectedOptions.has(option.id);

  useEffect(() => {
    if (open) {
      setSelectedOptions(
        new Set(
          organizationalEntities.filter((x) => x.is_enabled).map((x) => x.id)
        )
      );
      setIsUsingTopLevel(!!connection?.default_to_top_level);
    }

    if (!open) {
      setMessages([]);
      setIsUsingTopLevel(!!connection?.default_to_top_level);
    }
  }, [organizationalEntities, open, connection?.default_to_top_level]);

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        title=" Select entities you'd like to sync"
        hideCloseButton={!setup}
        disableOverlayClose={!setup}
      >
        <div className="w-[90vw] px-6 py-5 md:w-[528px]">
          <div className="flex flex-col gap-4">
            <SearchInput
              wide
              setValue={(e) => setSearch(e.target.value)}
              value={search}
              onSearch={(e) => setSearch(e)}
              label="Search"
            />
            {messages.map((x) => (
              <InlineAlert {...x} key={x.title} dismissable={true} />
            ))}
            <div className="flex justify-end gap-1 text-gray-500">
              <TextButton
                color="gray"
                label="Select all"
                onClick={handleSelectAll}
              />
              /
              <TextButton
                color="gray"
                label="Deselect all"
                onClick={handleDeselectAll}
              />
            </div>
            {selectableEntities.map((entity) => (
              <SelectionBox
                wide
                inline
                variant="snug"
                position="left"
                border="lg"
                key={entity.id}
                size="sm"
                title={entity.name}
                value={isSelected(entity)}
                selectionType="radio"
                showCheck={false}
                onClick={() => {
                  handleSelect(entity);
                }}
                badge={
                  <Badge
                    color={entity.is_top_level ? "teal" : "gray"}
                    label={
                      entity.is_top_level
                        ? "Top level"
                        : "Assign as top level entity"
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggleTopLevel(entity);
                    }}
                  />
                }
              />
            ))}
            <div>
              <div className="flex items-center justify-end gap-2">
                <ToggleInput
                  enabled={isUsingTopLevel}
                  onChange={handleToggle}
                />
                Use top level settings for all entities
              </div>
              {topLevelError && (
                <p className="text-right text-sm text-red-500">
                  Cannot set default top level settings for all entities
                </p>
              )}
            </div>

            <div className="flex items-center justify-end gap-2">
              <Button
                label="Cancel"
                outline
                color="transparent"
                onClick={handleCancel}
              />
              <Button
                label={!setup && connection ? "Sync" : "Save"}
                onClick={handleSync}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmModal
        open={deleteConnectionConfirmOpen}
        hideCloseButton={!setup}
        setOpen={setDeleteConnectionConfirmOpen}
        title="Are you sure?"
        subtitle="Exiting now will disconnect your accounting software"
        handleSave={handleDisconnect}
        handleClose={async () => {
          setOpen(true);
          setDeleteConnectionConfirmOpen(false);
        }}
        loading={isDeletingConnection}
        primaryLabel="Yes, disconnect"
        secondaryLabel="No, stay"
      />
    </>
  );
};

export default SelectEntitiesModal;
