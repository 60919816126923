import { Icon } from "@repo/ui";
import Link from "next/link";

export default function Footer() {
  return (
    <div
      className="Footer flex min-h-[160px] flex-col items-center space-y-2 p-4 text-gray-900"
      data-testid="footer"
    >
      <div className="text-base font-semibold text-gray-900">Powered by</div>
      <div className="flex flex-row items-center justify-center">
        <div className="QuicklyLogo mr-2">
          <Icon icon="bolt-gradient" size="32" />
        </div>
        <div className="font-gilroy flex flex-row items-center text-2xl font-bold text-gray-900">
          Quickly
        </div>
      </div>
      <div className="text-xs font-medium">
        &copy; 2023 Quickly Technologies Inc.
      </div>
      <div className="flex flex-row space-x-4">
        <Link
          aria-label="terms-of-service"
          href="https://helloquickly.com/terms-of-service"
          className="text-xs font-medium text-gray-500 underline hover:text-gray-900 focus:text-gray-900 focus:no-underline focus:outline-1 focus:outline-gray-900"
          target={"_blank"}
        >
          Terms of Service
        </Link>
        <Link
          aria-label="privacy-policy"
          href="https://helloquickly.com/privacy-policy"
          className="text-xs font-medium text-gray-500 underline hover:text-gray-900 focus:text-gray-900 focus:no-underline focus:outline-1 focus:outline-gray-900"
          target={"_blank"}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}
