import clsx from "clsx";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";

type PropsT = {
  open: boolean;
  value?: number;
  onSelect: (newDay: number) => void;
};

export const DayOfMonthSelector = forwardRef(function DayOfMonthSelector(
  props: PropsT,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { open, value, onSelect } = props;

  const [selectedValue, setSelectedValue] = useState(props.value || "");

  useEffect(() => {
    // value changed
    if (value?.toString() === "") {
      setSelectedValue("");
    }
  }, [value]);

  const handleSelect = (day: number) => {
    onSelect(day);
    setSelectedValue(day);
  };

  return (
    <div
      className={clsx(
        "Calendar z-30 mt-2 rounded-lg border border-gray-200 bg-white p-4 shadow-lg",
        {
          absolute: open,
          hidden: !open,
        }
      )}
      ref={ref}
    >
      <div className="mt-2 grid grid-cols-7 text-sm font-semibold">
        {Array.from({ length: 31 }, (_, i) => i + 1).map((day) => (
          <div key={day} className={clsx({ "text-gray-900": day > 6 })}>
            <button
              type="button"
              onClick={() => {
                handleSelect(day);
              }}
              className={clsx(
                parseInt(day.toString()) ===
                  parseInt(selectedValue.toString()) && "bg-black text-white",
                "flex h-8 w-8 items-center justify-center rounded-full"
              )}
            >
              {day}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
});
