import clsx from "clsx";

export type PropsT = {
  value: boolean | undefined;
  onChange: (e?: unknown) => void;
  disabled?: boolean;
  label?: string;
  id?: string;
  position?: "top" | "bottom" | "left" | "right"; // top, bottom, left, right
  align?: "center" | "top";
  "data-testid"?: string;
  size?: "sm" | "base";
};

export const CheckboxInput = (props: PropsT) => {
  const {
    value,
    onChange,
    disabled,
    label,
    id,
    position,
    align = "center",
    "data-testid": dataTestId,
    size = "base",
  } = props;

  const idForInput = id ? id : label;

  let labelStyle: string;

  switch (position) {
    case "top":
      labelStyle = "mb-1";
      break;
    case "right":
      labelStyle = "ml-2";
      break;
    case "bottom":
      labelStyle = "mt-1";
      break;
    case "left":
      labelStyle = "mr-2";
      break;
    default: // default to right
      labelStyle = "ml-2";
      break;
  }

  return (
    <div
      className={clsx(`CheckboxInput flex flex-row text-sm font-medium`, {
        "text-gray-900 hover:cursor-pointer": !disabled,
        "pointer-events-none text-gray-400 hover:cursor-default": disabled,
        "flex flex-col": position === "top" || position === "bottom",
        "items-center": align === "center",
      })}
    >
      {label && (position === "left" || position === "top") && (
        <label htmlFor={idForInput} className={`${labelStyle}`}>
          {label}
        </label>
      )}

      <input
        id={idForInput}
        data-testid={dataTestId || idForInput}
        className={clsx(
          `rounded-sm border-[1.5px] bg-white focus:ring-0 focus:ring-offset-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:ring-0`,
          {
            "text-gray-900 focus-visible:outline-gray-900": value && !disabled,
            "border-gray-300 focus-visible:outline-gray-500":
              !value && !disabled,
            "border-gray-500 hover:cursor-pointer hover:border-gray-800":
              !disabled,
            "border-gray-200 !bg-gray-100 hover:border-gray-200":
              disabled && !value,
            "!border-gray-400 !bg-gray-400 hover:cursor-default":
              disabled && value,
            "mt-1": align === "top",
            "h-3 w-3": size === "sm",
            "h-4 w-4": size === "base",
          }
        )}
        type="checkbox"
        checked={value}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={onChange}
        disabled={disabled}
      />

      {label &&
        (position === "right" || position === "bottom" || !position) && (
          <label
            htmlFor={idForInput}
            className={`w-full ${labelStyle} ${
              disabled ? "hover:cursor-default" : "hover:cursor-pointer"
            }`}
          >
            {label}
          </label>
        )}
    </div>
  );
};
