import { useState } from "react";
import {
  syncReleaseFundsEntries,
  syncRepaymentEntries,
} from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import { useToast } from "../../../shared/components";
import { getBuyerEPState, getBuyerRepaymentState } from "../helpers";

type PropsT = {
  invoice: InvoiceType;
  isBill: boolean;
  onSuccess?: () => void;
};

export const useJournalEntries = ({ invoice, isBill, onSuccess }: PropsT) => {
  const { company, selectedEntity } = useAuth();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const isJEReady =
    invoice.early_pay &&
    Boolean(company?.Connections?.[0]) &&
    Boolean(isBill && invoice?.buyer_erp_id);

  const epProcessingState = getBuyerEPState(invoice, selectedEntity);

  const repaymentProcessingState = getBuyerRepaymentState(
    invoice,
    selectedEntity
  );

  const isEPEntriesReady = isJEReady && epProcessingState.state === "ready";

  const isRepaymentEntriesReady =
    isJEReady &&
    invoice?.status === "paid-final" &&
    repaymentProcessingState.state === "ready";

  const isWithPendingEntries =
    (isEPEntriesReady && epProcessingState.state === "ready") ||
    (isRepaymentEntriesReady && repaymentProcessingState.state === "ready");

  const releaseFundsEntries = async () => {
    setIsLoading(true);
    syncReleaseFundsEntries({
      id: invoice?.id,
      type: "buyer",
    })
      .then(() => {
        toast({
          variant: "success",
          title: "Entries pushed successfully!",
        });
      })
      .finally(() => {
        setIsLoading(false);
        onSuccess?.();
      });
  };

  const repaymentEntries = async () => {
    setIsLoading(true);
    await syncRepaymentEntries({
      id: invoice?.id,
      type: "buyer",
    });
    toast({
      variant: "success",
      title: "Entries pushed successfully!",
    });
    setIsLoading(false);
    onSuccess?.();
  };

  return {
    isLoading,
    isJEReady,

    epProcessingState,
    repaymentProcessingState,
    isEPEntriesReady,
    isRepaymentEntriesReady,

    isWithPendingEntries,

    releaseFundsEntries,
    repaymentEntries,
  };
};
