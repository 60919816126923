import clsx from "clsx";
import Router, { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActiveERP,
  ConnectionModal,
  ContactSupportMessage,
  InactiveERP,
  IntegrationAccordion,
  IntegrationSettingsPanel,
  LoadingERP,
  SelectEntitiesModal,
} from ".";
import { getERPChartOfAccounts } from "../../../../apis/company";
import {
  APIResponseGetERPConnectionStatusT,
  deleteConnection,
  getERPConnectionStatus,
  getERPFinancialAccounts,
  getERPVendors,
  updateOrganizationalEntity,
} from "../../../../apis/erp";
import { useAuth } from "../../../../state";
import {
  Button,
  CheckboxInput,
  ConfirmModal,
  DropdownSearchInput,
  useToast,
} from "../../../shared/components";
import { useWarnIfUnsavedChanges } from "../../../shared/hooks";
import { computeQuicklyVendorState, defaultIfUndefined } from "../helpers";
import { useClearingAccount } from "../hooks";
import { IntegrationsChangesT } from "../types";

type PropsT = {
  onSave?: () => void;
  onCancel?: () => void;
  saveButtonLabel?: string;
  isSetup?: boolean;
  onConnectionSuccessChange?: (value: boolean) => void;
};

const verbiages: Record<keyof IntegrationsChangesT, string> = {
  auto_sync_bills: "Auto sync bills",
  auto_sync_invoices: "Auto sync invoices",
  quickly_vendor_erp_id: "Quickly vendor",
  bill_clearing_account_erp_id: "Bill clearing account",
  default_erp_bank_account_bills: "Default bill bank account",
  default_erp_credit_card_bills_erp_id: "Default bill credit card account",
  default_discounts_account_erp_id: "Default discounts account",
  record_to_bill_account: "Record to bill account",
  default_fees_account_erp_id: "Default fees account",
  default_erp_bank_account_invoices: "Default invoice bank account",
  quickly_customer_erp_id: "Quickly customer",
  invoice_early_payment_expense_account_erp_id:
    "Invoice early payment expense account",
  invoice_clearing_account_erp_id: "Invoice clearing account",
};

const computeDirtyChangesString = (changes: Partial<IntegrationsChangesT>) => {
  const changesArray = Object.keys(changes);
  const items: string[] = [];

  for (const change of changesArray) {
    items.push(verbiages[change as keyof IntegrationsChangesT]);
  }

  return items;
};

const SetupERP = (props: PropsT) => {
  const {
    onSave,
    onCancel,
    saveButtonLabel = "Save Changes",
    isSetup = false,
    onConnectionSuccessChange,
  } = props;

  const {
    user,
    company,
    selectedEntity,
    organizationalEntities,
    setSelectedEntity,
    getCompany,
    getAllOrganizationalEntities,
  } = useAuth();

  const router = useRouter();
  const { toast } = useToast();

  const editEntities = router.query?.["edit-entities"];

  // Loading states
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [connectionStatusIsLoading, setConnectionStatusIsLoading] =
    useState(false);
  const [isFirstTimeSetup, setIsFirstTimeSetup] = useState(false);

  // Modal State
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const [selectEntitiesModalOpen, setSelectEntitiesModalOpen] = useState(false);
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);
  const [confirmChangesModalOpen, setConfirmChangesModalOpen] = useState(false);

  // Data state
  const [connectionState, setConnectionState] =
    useState<APIResponseGetERPConnectionStatusT>();
  const [erpVendors, setErpVendors] = useState<ContactType[]>([]);
  const [chartOfAccounts, setChartOfAccounts] = useState<ERPAccountT[]>([]);
  const [financialAccounts, setFinancialAccounts] = useState<AccountType[]>([]);

  // Form state
  const [dirty, setDirty] = useState(false);
  const [changes, setChanges] = useState<Partial<IntegrationsChangesT>>({});

  const handleChange = <K extends keyof IntegrationsChangesT>(
    key: K,
    value: IntegrationsChangesT[K] | null
  ) => {
    setDirty(true);
    setChanges((prevChanges) => ({ ...prevChanges, [key]: value || null }));
  };

  const updateConnectionState = useCallback(async () => {
    setConnectionStatusIsLoading(true);
    return getERPConnectionStatus()
      .then((connectionStatusResponse) => {
        setConnectionState(connectionStatusResponse);
        setConnectionStatusIsLoading(false);
        return connectionStatusResponse;
      })
      .catch(() => setConnectionStatusIsLoading(false));
  }, []);

  // Derived state
  const connections = company?.Connections || [];
  const connection = connections?.[0];

  // Entity Derived State
  const topLevelEntity = organizationalEntities.find((x) => x.is_top_level);
  const activeEntity = connection?.default_to_top_level
    ? topLevelEntity
    : selectedEntity;

  // ERP Status Derived State
  const activeERP = Boolean(connection) && connection.is_setup_complete;
  const status = connectionState?.status;
  const isConnectionError =
    status?.status === "invalid" || status?.status === "expired";
  const isConnectionPending = status?.status === "pending";
  const isLoading = isConnectionPending || connectionStatusIsLoading || loading;
  const isConnectionSuccess = status?.status === "active" && activeERP;

  const isBuyerEPSetupComplete =
    (Boolean(activeEntity?.bill_clearing_account_erp_id) ||
      Boolean(activeEntity?.bill_clearing_account_pushcommunicationid)) &&
    (Boolean(activeEntity?.quickly_vendor_erp_id) ||
      Boolean(activeEntity?.vendor_pushcommunicationid));
  const isBuyerReleaseFundsSetupComplete =
    Boolean(activeEntity?.default_erp_bank_account_bills) ||
    Boolean(activeEntity?.default_erp_credit_card_bills_erp_id);

  useWarnIfUnsavedChanges(
    dirty && !isSetup,
    useCallback(() => {
      return confirm(
        "You have unsaved changes, are you sure you wish to proceed?"
      );
    }, [])
  );

  useEffect(() => {
    if (onConnectionSuccessChange) {
      onConnectionSuccessChange(isConnectionSuccess);
    }
  }, [isConnectionSuccess, onConnectionSuccessChange]);

  useEffect(() => {
    if (activeERP) {
      getERPChartOfAccounts().then((chartOfAccountsResponse) => {
        if (chartOfAccountsResponse?.success) {
          setChartOfAccounts(chartOfAccountsResponse.data);
        } else {
          console.error("Failed to fetch chart of accounts");
        }
      });

      getERPFinancialAccounts().then((financialAccountsResponse) => {
        if (financialAccountsResponse?.success) {
          setFinancialAccounts(financialAccountsResponse.accounts);
        } else {
          console.error("Failed to fetch financial of accounts");
        }
      });
    }
  }, [activeERP]);

  const billClearingAccount = useClearingAccount({
    chartOfAccounts,
    entity: activeEntity || null,
    changes,
    field: "bill_clearing_account_erp_id",
    handleChange,
    type: "bill-clearing-account",
  });

  const queryERPVendors = useCallback(async () => {
    const response = await getERPVendors();
    setErpVendors(response.vendors || []);
  }, []);

  const queryAccounts = useCallback(
    (query: string) =>
      (chartOfAccounts || [])
        ?.filter((acc) => acc?.name.toLowerCase().includes(query.toLowerCase()))
        .map((acc) => ({
          label: acc?.name,
          value: acc?.erp_id,
        })),
    [chartOfAccounts]
  );

  const queryFinancialAccounts = useCallback(
    (query: string) =>
      (financialAccounts || [])
        ?.filter((acc) => acc?.name.toLowerCase().includes(query.toLowerCase()))
        .map((acc) => ({
          label: acc?.name,
          value: acc?.erp_id,
        })),
    [financialAccounts]
  );

  const computedTradeDiscountsAccount = useMemo(() => {
    const id = defaultIfUndefined(
      activeEntity?.default_discounts_account_erp_id,
      changes?.default_discounts_account_erp_id
    );
    const tradeDiscount = chartOfAccounts.find((x) => x?.erp_id === id);
    return (
      {
        label: tradeDiscount?.name || "",
        value: tradeDiscount?.erp_id || "",
      } || undefined
    );
  }, [
    chartOfAccounts,
    changes?.default_discounts_account_erp_id,
    activeEntity?.default_discounts_account_erp_id,
  ]);

  const computedFeesAccount = useMemo(() => {
    const id = defaultIfUndefined(
      activeEntity?.default_fees_account_erp_id,
      changes?.default_fees_account_erp_id
    );
    const feesAccount = chartOfAccounts.find((x) => x?.erp_id === id);
    return (
      {
        label: feesAccount?.name || "",
        value: feesAccount?.erp_id || "",
      } || undefined
    );
  }, [
    chartOfAccounts,
    changes?.default_fees_account_erp_id,
    activeEntity?.default_fees_account_erp_id,
  ]);

  const computedQuicklyVendor = useMemo(
    () =>
      computeQuicklyVendorState(
        "Quickly Technologies Inc. - Vendor",
        erpVendors,
        defaultIfUndefined(
          activeEntity?.quickly_vendor_erp_id,
          changes?.quickly_vendor_erp_id
        ),
        activeEntity?.vendor_pushcommunicationid,
        connectionState?.quicklyVendorState
      ),
    [
      changes?.quickly_vendor_erp_id,
      activeEntity?.quickly_vendor_erp_id,
      activeEntity?.vendor_pushcommunicationid,
      erpVendors,
      connectionState?.quicklyVendorState,
    ]
  );

  const bankAccount = useMemo(() => {
    const id =
      changes?.default_erp_bank_account_bills !== undefined
        ? changes?.default_erp_bank_account_bills
        : activeEntity?.default_erp_bank_account_bills;
    const acc = financialAccounts.find((x) => x?.erp_id === id);

    if (!acc || !acc.erp_id) {
      return;
    }
    return {
      label: acc.name,
      value: acc.erp_id,
    };
  }, [
    financialAccounts,
    changes?.default_erp_bank_account_bills,
    activeEntity?.default_erp_bank_account_bills,
  ]);

  const creditCardGlAccount = useMemo(() => {
    const id =
      changes?.default_erp_credit_card_bills_erp_id !== undefined
        ? changes?.default_erp_credit_card_bills_erp_id
        : activeEntity?.default_erp_credit_card_bills_erp_id;
    const acc = chartOfAccounts.find((x) => x?.erp_id === id);

    if (!acc) return;

    return {
      label: acc?.name,
      value: acc?.erp_id,
    };
  }, [
    chartOfAccounts,
    changes?.default_erp_credit_card_bills_erp_id,
    activeEntity?.default_erp_credit_card_bills_erp_id,
  ]);

  useEffect(() => {
    if (activeERP) queryERPVendors();
  }, [activeERP, queryERPVendors]);

  useEffect(() => {
    if (activeERP) updateConnectionState();
  }, [updateConnectionState, activeERP]);

  useEffect(() => {
    if (editEntities) {
      setSelectEntitiesModalOpen(true);
      setIsFirstTimeSetup(!connection?.is_setup_complete);
      Router.push("/profile/integrations");
    }
  }, [editEntities, connection?.is_setup_complete]);

  const handleAddedConnection = async (executed: number = 0): Promise<void> => {
    setOpenConnectModal(false);
    setLoading(true);
    if (user?.token) {
      const connectionStatusResponse = await updateConnectionState();
      if (!connectionStatusResponse?.status?.status && executed < 5) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return handleAddedConnection(executed + 1);
      }
      await getCompany(user.token);
    }

    setLoading(false);
  };

  const handleDisconnectERP = async () => {
    setDisconnectLoading(true);
    if (user?.token && connection?.id) {
      await deleteConnection(connection.id);
      setSelectedEntity(null);
      const [request] = await Promise.all([
        updateConnectionState(),
        getCompany(user.token),
      ]);
      if (
        request
        // && request?.success
      ) {
        toast({
          variant: "success",
          title: `You've disconnected from ${connection?.connection_type} successfully!`,
        });
      }
    }
    setDisconnectLoading(false);
    setConfirmRemoveModal(false);
  };

  const getVendors = (query: string) => {
    let vendors: ContactType[] = erpVendors || [];

    if (query) {
      vendors = vendors.filter((x) =>
        x.company_name?.toLowerCase().includes(query.toLowerCase())
      );
    }

    return vendors.map((vendor) => ({
      label: vendor.company_name,
      value: vendor.erp_id || "",
    }));
  };

  const handleSave = async () => {
    if (!user?.token || !activeEntity?.id) return;

    setSaving(true);
    updateOrganizationalEntity(activeEntity.id, changes)
      .then(async (req) => {
        if (!req.success) throw Error("Failed to update Company");
        await getAllOrganizationalEntities();
        toast({
          variant: "success",
          title: "Account settings updated successfully!",
        });
        setSelectedEntity(req.entity);
        setDirty(false);
        setChanges({});
        setConfirmChangesModalOpen(false);
        onSave?.();
      })
      .catch((e) => {
        toast({
          variant: "error",
          title:
            "An error occured whiled connecting your account. Please contact support for assistance.",
        });

        console.error(e);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <LoadingERP />
      ) : isConnectionSuccess ? (
        <ActiveERP
          isSetup={isSetup}
          connection={connection}
          onDisconnect={() => setConfirmRemoveModal(true)}
          lastSyncAt={company?.last_sync_at}
        />
      ) : (
        <InactiveERP onConnect={() => setOpenConnectModal(true)}>
          {isConnectionError && (
            <ContactSupportMessage message="An error occurred while trying to connect to your account." />
          )}
        </InactiveERP>
      )}

      <IntegrationSettingsPanel
        isSetup={isSetup}
        isActive={activeERP && !!activeEntity}
        isAutoSync={defaultIfUndefined(
          Boolean(activeEntity?.auto_sync_bills),
          changes["auto_sync_bills"]
        )}
        setAutoSync={(state) => handleChange("auto_sync_bills", state)}
        title="Transaction settings"
        description="Define records relating to payments and bills."
        toggleLabel="Automatically pull bills into Quickly"
      >
        <IntegrationAccordion
          data-testid="clearing-accordion"
          complete={isBuyerEPSetupComplete}
          disabled={!activeERP || !activeEntity}
          title="Vendor swap"
        >
          <div
            className={clsx(
              "flex gap-6 rounded-lg border border-gray-200 bg-white p-6",
              {
                "flex-col justify-start sm:flex-row sm:gap-5": !isSetup,
                "flex-col": isSetup,
              }
            )}
          >
            <div className="w-full">
              <div className="mb-3 text-gray-500">
                Create a clearing account that allows us to change a bill from
                &apos;Owing to your supplier&apos;, to &apos;Owing to
                Quickly&apos;.
              </div>
              <DropdownSearchInput
                data-testid="bill-clearing-account-dropdown"
                debounceTime={0}
                getOptions={queryAccounts}
                wide
                placeholder="Select a clearing account"
                label="Clearing Account"
                key={billClearingAccount.key}
                {...billClearingAccount.dropdown}
              />
            </div>

            <div className="flex w-full items-center justify-center">
              <div className="rounded-md bg-gray-100 p-4 text-sm text-gray-900">
                This account lives in the short term liabilities section of your
                chart of accounts and behaves like a credit card to allow us to
                pay the supplier bill. We then clear the balance by creating a
                bill from Quickly. This account should always be at a zero
                balance.
              </div>
            </div>
          </div>

          <div className="mt-5 flex flex-col items-center justify-start gap-5 sm:flex-row">
            <div className="w-full rounded-lg border border-gray-200 bg-white p-4">
              <div className="mb-3 text-gray-500">
                We&apos;ll need to create Quickly as a supplier in your system.
              </div>
              <DropdownSearchInput
                onSelect={(option) =>
                  handleChange("quickly_vendor_erp_id", option?.value)
                }
                debounceTime={0}
                key={`quickly-vendor-${computedQuicklyVendor?.value}`}
                value={computedQuicklyVendor}
                getOptions={getVendors}
                wide
                placeholder="Select Quickly as a supplier"
                label="Quickly supplier"
              />
            </div>

            {!isSetup && <div className="sm:w-full"></div>}
          </div>
        </IntegrationAccordion>

        <IntegrationAccordion
          complete={isBuyerReleaseFundsSetupComplete}
          disabled={
            !activeERP ||
            !activeEntity ||
            connection?.connection_type === "sage300"
          }
          disabledReason={
            connection?.connection_type === "sage300"
              ? "Payments is current not supported for Sage 300"
              : ""
          }
          title="Payments"
        >
          <div
            className={clsx("flex flex-wrap gap-5 bg-white ", {
              "flex-col sm:flex-row": !isSetup,
              "flex-col": isSetup,
            })}
          >
            <div className="flex flex-1 flex-col gap-3 rounded-lg border border-gray-200 bg-white p-4 sm:min-w-64 sm:p-6">
              <div className="text-gray-500">
                Which account should we record payments to?
              </div>
              <DropdownSearchInput
                wide
                getOptions={queryFinancialAccounts}
                onSelect={(option) =>
                  handleChange(
                    "default_erp_bank_account_bills",
                    option?.value || ""
                  )
                }
                debounceTime={0}
                placeholder="Select a bank account"
                key={`bank-account-${bankAccount?.value}`}
                value={bankAccount}
                label="Bank Account"
              />
              <DropdownSearchInput
                wide
                getOptions={queryAccounts}
                key={`credit-card-${creditCardGlAccount?.value}`}
                value={creditCardGlAccount}
                onSelect={(option) =>
                  handleChange(
                    "default_erp_credit_card_bills_erp_id",
                    option?.value || ""
                  )
                }
                debounceTime={0}
                placeholder="Select a credit card account"
                label="Credit Card Account"
              />
            </div>

            <div className="flex flex-1 flex-col gap-3 rounded-lg border border-gray-200 bg-white p-4 sm:min-w-64 sm:p-6">
              <div className="text-gray-500">
                Where should we record your early payment discounts?
              </div>
              <DropdownSearchInput
                onSelect={(option) =>
                  handleChange(
                    "default_discounts_account_erp_id",
                    option?.value || ""
                  )
                }
                debounceTime={0}
                key={`trade-discounts-${computedTradeDiscountsAccount?.value}`}
                value={computedTradeDiscountsAccount}
                getOptions={queryAccounts}
                disabled={
                  changes.record_to_bill_account ||
                  activeEntity?.record_to_bill_account
                }
                wide
                placeholder="Select trade discounts account"
                label="Trade Discounts Account"
              />

              <div className="flex items-center">
                <div className="flex-1 border-t border-gray-200"></div>
                <span className="mx-4 text-sm text-gray-900">OR</span>
                <div className="flex-1 border-t border-gray-200"></div>
              </div>

              <CheckboxInput
                align="top"
                value={
                  defaultIfUndefined(
                    Boolean(activeEntity?.record_to_bill_account),
                    changes["record_to_bill_account"]
                  ) || false
                }
                onChange={() => {
                  const value = defaultIfUndefined(
                    !activeEntity?.record_to_bill_account,
                    changes["record_to_bill_account"] === true ? false : true
                  );

                  if (value) {
                    handleChange("record_to_bill_account", value);
                    handleChange("default_discounts_account_erp_id", "");
                  } else {
                    handleChange("record_to_bill_account", value);
                  }
                }}
                label="Record to the cost account related to the bill"
              />
            </div>

            <div className="flex flex-1 flex-col gap-3 rounded-lg border border-gray-200 bg-white p-4 sm:min-w-64 sm:p-6">
              <div className="text-gray-500">
                Where should we record your transaction fees?
              </div>
              <DropdownSearchInput
                onSelect={(option) =>
                  handleChange(
                    "default_fees_account_erp_id",
                    option?.value || ""
                  )
                }
                debounceTime={0}
                key={`fees-${computedFeesAccount?.value}`}
                value={computedFeesAccount}
                getOptions={queryAccounts}
                wide
                placeholder="Select fees account"
                label="Fees Account"
              />
            </div>
          </div>
        </IntegrationAccordion>
      </IntegrationSettingsPanel>

      <div className="flex justify-end gap-x-3">
        {onCancel && (
          <Button
            label="Cancel"
            color={"transparent"}
            outline
            onClick={onCancel}
          />
        )}

        <Button
          label={saveButtonLabel}
          disabled={!dirty && !isSetup}
          loading={saving}
          data-testid="save-button"
          onClick={handleSave}
        />
      </div>

      <ConnectionModal
        open={openConnectModal}
        setOpen={setOpenConnectModal}
        onSuccess={handleAddedConnection}
        setSelectEntitiesModalOpen={(val) => {
          if (val) {
            setOpenConnectModal(false);
            setIsFirstTimeSetup(true);
          }
          setSelectEntitiesModalOpen(val);
        }}
      />

      <SelectEntitiesModal
        open={selectEntitiesModalOpen}
        setOpen={setSelectEntitiesModalOpen}
        setup={!!connection?.is_setup_complete || !isFirstTimeSetup}
        onSuccess={() => {
          handleAddedConnection();
          setSelectEntitiesModalOpen(false);
          setIsFirstTimeSetup(false);
        }}
      />

      <ConfirmModal
        open={confirmChangesModalOpen}
        setOpen={setConfirmChangesModalOpen}
        handleSave={handleSave}
        handleClose={() => setConfirmChangesModalOpen(false)}
        loading={loading}
        title={
          topLevelEntity && connection?.default_to_top_level
            ? "Confirm changes for all entities"
            : `Confirm changes for ${activeEntity?.name} entity`
        }
        subtitle={
          topLevelEntity && connection?.default_to_top_level ? (
            <>
              You are about to make changes to:
              <span className="my-4 list-disc pl-4">
                {computeDirtyChangesString(changes).map((k) => (
                  <li key={k}>{k}</li>
                ))}
              </span>
              for all entities associated with your Quickly account. Would you
              like to proceed?
            </>
          ) : (
            <>
              You are about to make changes to:
              <span className="my-4 list-disc pl-4">
                {computeDirtyChangesString(changes).map((k) => (
                  <div key={k}>{k}</div>
                ))}
              </span>
              for {activeEntity?.name}. Would you like to proceed?
            </>
          )
        }
        secondaryLabel="Cancel"
        primaryLabel="Confirm"
      />

      <ConfirmModal
        open={confirmRemoveModal}
        setOpen={() => setConfirmRemoveModal(false)}
        handleSave={handleDisconnectERP}
        handleClose={() => setConfirmRemoveModal(false)}
        loading={disconnectLoading}
        title="Remove connection"
        subtitle="Are you sure you want to remove syncing with this accounting software?"
        secondaryLabel="Cancel"
        primaryLabel="Confirm"
      />
    </>
  );
};

export default SetupERP;
