import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import Router from "next/router";
import { useState } from "react";
import { useAuth } from "../../../../state";
import SelectButton from "../../../shared/components/input/selectButton";
import SelectEntitiesFilter from "./selectEntitiesFilter";

function SelectEntitiesDropdownHeader() {
  const { selectedEntity, organizationalEntities } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(16),
      shift(),
      flip({
        fallbackAxisSideDirection: "start",
      }),
    ],
  });

  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    focus,
    dismiss,
    role,
  ]);

  if (organizationalEntities?.length <= 1) {
    return;
  }

  return (
    <div
      data-testid="select-entities-dropdown-header"
      className="relative top-16 border-b border-solid border-b-gray-200 px-3 py-2 md:top-0"
    >
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className="flex w-full items-center justify-end"
      >
        <SelectButton
          data-testid="open-entities-filter-button"
          label={selectedEntity?.name || "None"}
          onClick={() => setIsOpen(!isOpen)}
          active={isOpen}
        />
      </div>

      <FloatingPortal>
        {isOpen && (
          <div
            data-testid="select-entities-filter-container"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className="relative z-40"
          >
            <SelectEntitiesFilter
              onEdit={() => {
                Router.push("/profile/integrations?edit-entities=true");
                setIsOpen(false);
              }}
              onSelect={() => setIsOpen(false)}
            />
          </div>
        )}
      </FloatingPortal>
    </div>
  );
}

export default SelectEntitiesDropdownHeader;
