import clsx from "clsx";
import React, { useCallback } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

type PropsT = {
  setOpen: (arg: boolean) => void;
  items?: ItemT[];
};

export const ContextMenuItems = (props: PropsT) => {
  const { setOpen, items = [] } = props;

  const listItem = useCallback(
    (item: ItemT) => {
      if (item?.custom) {
        return item.custom;
      } else if (item?.disabled) {
        return (
          <li
            key={`${item.label}-listItem`}
            className="mx-1 !cursor-default px-4 py-2"
            data-testid={item.label + "-item-disabled"}
          >
            <Tooltip title={item.disabledReason} position="bottom">
              <div className="flex w-full flex-row items-center opacity-50">
                {item.icon ? (
                  <span className="mr-3 inline-flex">
                    {item.loading ? (
                      <Icon
                        icon="download-simple"
                        size="16"
                        iconStyle="fill-gray-900"
                      />
                    ) : (
                      <Icon
                        icon={item.icon}
                        size="16"
                        iconStyle={
                          item.danger ? "fill-orange-800" : "fill-gray-900"
                        }
                      />
                    )}
                  </span>
                ) : (
                  <div className="ml-4" />
                )}

                {item.label && (
                  <span
                    className={clsx("text-sm font-medium", {
                      "text-gray-700": !item.danger,
                      "text-orange-800": item.danger,
                    })}
                  >
                    {item.label}
                  </span>
                )}
              </div>
            </Tooltip>
          </li>
        );
      } else if (item?.hidden) {
        return null;
      } else {
        return (
          <li
            key={`${item.label}-listItem`}
            onClick={async (e) => {
              e.stopPropagation();
              if (item?.onClick) item.onClick();
              setOpen(false);
            }}
            className={clsx(
              "mx-1 flex cursor-pointer items-center rounded-md px-4 py-2 hover:bg-gray-100",
              {
                "pointer-events-none": item?.disabled,
              }
            )}
            data-testid={item.label + "-item"}
          >
            {item.icon ? (
              <span className="mr-2 inline-flex">
                {item.loading ? (
                  <Icon
                    icon="download-simple"
                    size="16"
                    iconStyle="fill-gray-900"
                  />
                ) : (
                  <Icon
                    icon={item.icon}
                    size="16"
                    iconStyle={
                      item.danger ? "fill-orange-800" : "fill-gray-900"
                    }
                  />
                )}
              </span>
            ) : (
              <div className="ml-6" />
            )}
            {item.label && (
              <span
                className={clsx("text-sm font-medium", {
                  "text-gray-700": !item.danger,
                  "text-orange-800": item.danger,
                })}
              >
                {item.label}
              </span>
            )}
          </li>
        );
      }
    },
    [setOpen]
  );

  return (
    <ul
      className={`${clsx(
        "ContextMenuOpen -mt-[1px] w-64 rounded-lg border border-b border-gray-200 bg-white py-1 shadow-lg focus-visible:outline-none"
      )}`}
    >
      {items.length ? (
        items.map((item: ItemT, index: number) => (
          <React.Fragment key={index}>
            {listItem(item)}
            {item.border && (
              <div className="my-1 w-full border-b border-gray-200" />
            )}
          </React.Fragment>
        ))
      ) : (
        <li className="px-6 py-2 text-sm font-medium text-gray-900">
          No menu items
        </li>
      )}
    </ul>
  );
};
