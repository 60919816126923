import { Button, Icon } from "@repo/ui";
import { useEffect, useState } from "react";

//todo: delete this after july 1st and add tehse alerts as a part of global alerts rework
const DelayBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const dismissed = localStorage.getItem("recdaybanner");
    const now = new Date();
    const startDate = new Date(now.getFullYear(), 9, 27); // Sept 27th

    const endDate = new Date(now.getFullYear(), 10, 1); // Oct 1st

    if (!dismissed && now < endDate && now > startDate) {
      setIsVisible(true);
    }
  }, []);

  const dismissBanner = () => {
    localStorage.setItem("recdaybanner", "true");
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="alert-banner fixed inset-x-0 top-[60px] z-50 flex justify-between rounded-lg border border-[#ffe58f] bg-[#fffbe6] p-4 shadow-xl md:top-4 md:ml-[91px] md:mr-8">
      <div>
        <div className="flex flex-row">
          <div className="mr-4">
            <Icon icon="warning-fill" iconStyle="fill-yellow-300" size={"24"} />
          </div>
          <div>
            <div className="text-lg font-bold text-gray-900">
              EFT&apos;s will not be processed on National Day for Truth and
              Reconciliation.
            </div>
            <div className="text-sm text-gray-500">
              All requests after 4pm MST on Sept 27 will be processed Tuesday
              Oct 1st
            </div>
          </div>
        </div>
      </div>

      <Button
        customIconSize="20"
        icon="x"
        size="sm"
        color="transparent"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dismissBanner();
        }}
      />
    </div>
  );
};

export default DelayBanner;
