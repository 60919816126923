import { fetchWithAuth, fetchWithOutAuth } from "../interceptor";
import {
  APIResponseBatchEnableBillSyncT,
  APIResponseCreateContact,
  APIResponseGetContacts,
  APIResponseGetTags,
  APIResponseUpdateContact,
} from "./types";

const url = "/company/contacts";

export const createContact = async (props: {
  inviter_id: number;
  company_name: string;
  email: string;
  role: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  payment_terms?: number | string | null;
}): Promise<APIResponseCreateContact> => {
  const {
    inviter_id,
    company_name,
    email,
    role,
    first_name,
    last_name,
    phone,
    payment_terms,
  } = props;

  return fetchWithAuth(url, {
    method: "POST",
    body: JSON.stringify({
      contact: {
        inviter_id,
        company_name,
        email,
        role,
        first_name,
        last_name,
        phone,
        payment_terms: payment_terms
          ? parseInt(payment_terms.toString())
          : null,
      },
    }),
  });
};

export const updateContact = async (props: {
  contact: Partial<ContactType>;
}): Promise<APIResponseUpdateContact> => {
  const { contact } = props;

  return fetchWithAuth(`${url}/${contact.id}`, {
    method: "PUT",
    body: JSON.stringify({ contact }),
  });
};

export const deleteContact = async (props: {
  contact_id: number;
}): Promise<APIResponse> => {
  const { contact_id } = props;

  return fetchWithAuth(`${url}/${contact_id}`, { method: "DELETE" });
};

export const inviteContact = async (props: {
  contact_ids: number[];
}): Promise<APIResponse> => {
  const { contact_ids } = props;

  return fetchWithAuth(`${url}/invite`, {
    method: "POST",
    body: JSON.stringify({
      contact_ids,
    }),
  });
};

export const resendContactInvite = async (props: {
  contact_id: number;
}): Promise<APIResponse> => {
  const { contact_id } = props;

  return fetchWithOutAuth(`${url}/${contact_id}/reinvite`, {
    method: "PUT",
  });
};

export const getCompanyContacts = async (props: {
  offset?: number;
  limit?: number;
  query?: string | null;
  type?: "customer" | "vendor";
  erpEntityId?: string;
}): Promise<APIResponseGetContacts> => {
  const { offset = 0, limit = 20, query = "", type = "", erpEntityId } = props;

  let newURL = `${url}?`;
  if (limit) newURL += `limit=${limit}`;
  if (offset) newURL += `&offset=${offset}`;
  if (query) newURL += `&q=${query}`;
  if (type) newURL += `&type=${type}`;
  if (erpEntityId) newURL += `&erpEntityId=${erpEntityId}`;

  return fetchWithAuth(newURL, { method: "GET" });
};

export const getCompanyTags = async (props: {
  offset?: number;
  limit?: number;
  query?: string | null;
}): Promise<APIResponseGetTags> => {
  const { offset = 0, limit = 20, query = "" } = props;

  let newURL = `/company/tags?`;
  if (limit) newURL += `limit=${limit}`;
  if (offset) newURL += `&offset=${offset}`;
  if (query) newURL += `&q=${query}`;

  return fetchWithAuth(newURL, { method: "GET" });
};

export const batchEnableBillSyncForContacts = async (props: {
  contact_ids: number[];
}): Promise<APIResponseBatchEnableBillSyncT> => {
  return fetchWithAuth(`${url}/enableSync`, {
    method: "POST",
    body: JSON.stringify({
      contact_ids: props.contact_ids,
    }),
  });
};
